import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  Card,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card.tsx";

import { SkipSubscription } from "@/pages/subscription-manage/components/skip-subscription.tsx";
import { ReActivateSubscription } from "@/pages/subscription-manage/components/re-activate-subscription.tsx";

import { Subscription, SubscriptionStatus } from "@/core";
import { CancelWizard } from "@/pages/subscription-manage/components/cancel-wizard.tsx";

interface CancelOrPauseProps {
  subscription: Subscription;
}

export const CancelOrSkip: FC<CancelOrPauseProps> = (props) => {
  const { subscription } = props;
  const { t } = useTranslation("translation");

  const notFailedNorCancelled = useMemo(() => {
    return (
      subscription.status !== SubscriptionStatus.FAILED &&
      subscription.status !== SubscriptionStatus.CANCELLED
    );
  }, [subscription.status]);

  return (
    <Card className={"flex flex-col justify-between"}>
      <CardHeader>
        <CardTitle className={"text-xl font-hkgwb uppercase"}>
          {t("cancelOrPause")}
        </CardTitle>
      </CardHeader>
      <CardFooter>
        {notFailedNorCancelled && (
          <SkipSubscription subscription={subscription} />
        )}
        {notFailedNorCancelled && <CancelWizard />}

        {(subscription.status === SubscriptionStatus.CANCELLED ||
          subscription.status === SubscriptionStatus.FAILED) && (
          <ReActivateSubscription />
        )}
      </CardFooter>
    </Card>
  );
};
