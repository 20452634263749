export default {
  translation: {
    enterYourEmail: "Enter your email",
    enterYourPassword: "Enter your password",
    seeWhenIsYourNextDelivery:
      "See when is your next delivery and give yourself some extras.",
    rootManageSubscription:
      "Manage your Coffydoor subscription - pause, change and update.",
    userPoints: "User Points",
    yes: "Yes",
    home: "Home",
    shop: "Shop",
    cancelQuestion: "Are you sure you want to cancel?",
    pleaseEnterReason: "Please enter a reason",
    logOut: "Log out",
    coffe: "Coffee",
    faq: "FAQ",
    aboutUs: "About Us",
    contact: "Contact",
    login: "Login",
    hello: "Hello",
    areYouSure: "Are you sure?\n",
    no: "No",
    billedNowSuccessfully: "Bill now successfully",
    failedToBillNow: "Failed to bill now",
    subtotal: "Subtotal",
    loading: "Loading",
    loadingDescription: "Please wait while we load the data",
    total: "Total",
    discount: "Discount",
    discountCodeRemoved: "Discount code removed",
    summary: "Summary",
    describeBillNow: "You will be billed for the next month immediately.",
    discountCodeDescription: "Apply discount code here",
    itsTooExpensive: "It's too expensive",
    wrongOrder: "Wrong order",
    tooMuchCoffee: "I have too much coffee at home",
    needFasterDelivery: "I need faster delivery",
    stoppedDrinkingCoffee: "I have stopped drinking coffee",
    coffeeProblem: "Problems with the coffee",
    otherReason: "Other reason",
    reactivateSubscription: "Re-Activate subscription",
    areYouSureReActivateYourSubscription:
      "Are you sure , you want to re-activate your subscription?",
    back: "Back",
    // Pages
    // ** Orders
    noOrdersFound: "No orders found",
    noOrdersFoundDescription:
      "You haven't placed any orders yet. If you believe this is an error, please contact support for further assistance.",
    errorLoadingOrders: "Error loading orders",
    errorLoadingOrdersDescription:
      "An error occurred while loading your orders. Please try again later.",
    // ** Customer
    noCustomerData: "No customer data found",
    noCustomerDataMessage:
      "You haven't placed any orders yet. If you believe this is an error, please contact support for further assistance.",
    customerError: "Customer error",
    customerErrorMessage:
      "An error occurred while loading your customer data. Please try again later.",

    // ** Subscription
    noSubscriptionFound: "No subscription found",
    noSubscriptionFoundMessage:
      "If you believe this is an error, please contact support for further assistance.",
    subscriptionError: "Subscription error",
    subscriptionErrorMessage:
      "An error occurred while loading your subscription data. Please try again later.",
    // ** Subscription

    // ** Address
    noAddressFound: "No address found",
    noAddressFoundMessage:
      "You haven't added any addresses yet. If you believe this is an error, please contact support for further assistance.",
    addressError: "Address error",
    addressErrorMessage:
      "An error occurred while loading your address data. Please try again later.",
    // ** Address

    // Pages End

    deleteDiscountCode: "Delete Discount Code",
    deleteDiscountCodeDescription:
      "Are you sure you want to delete this discount code?",
    active: "Active",
    ACTIVE: "Active",
    PAUSED: "Paused",
    CANCELLED: "Cancelled",
    FAILED: "Failed",
    subscriptionPaused: "Subscription Paused",
    error: "Error",
    editProfile: "Edit Profile",
    anErrorOccurred: "An error occurred",
    subscriptionPausedDescription:
      "Your subscription has been paused. You can resume it at any time.",
    subscriptionCancelled: "Subscription Cancelled",
    subscriptionCancelledDescription:
      "Your subscription has been cancelled. You can resume it at any time.",
    cancelSubscriptionSecondDescription:
      "Instead, pause your membership and keep all your benefits!",
    buyWithCard: "Buy with card",
    youAre: "You are",
    pointsAway: "points away",
    usePoints: "Use points",

    spentPoints: "Every 10 kr spent are 1 point",
    subscription: "Subscription",
    manageSubscription: "Manage Subscription",
    orderHistory: "Order History",
    somethingElse: "Something else?",
    myProfile: "My Profile",
    subscriptionDescription:
      "Check when your next delivery is scheduled and consider adding some extra products for yourself.",
    nextDelivery: "Next Delivery",
    yourNextDelivery: "Your next delivery ships on",
    shipsEveryWeek: "Ships every 8 weeks",
    shippingAddress: "Shipping Address",
    addToYourNextOrder: "Add to your next order",
    oneTime: "One-time",
    nextDeliveryD: "Next Delivery Date",
    nextDeliveryDate: "Next delivery date updated",
    order: "Order",
    nextDeliveryDateIsBeforeToday: "Next delivery date is before today",
    success: "Success",
    failedToUpdateCustomerData: "Failed to update next delivery date",

    couponTooltip:
      "Introduce Coffydoor to a friend and both you and your friend will receive 100 kr discount on your next order.",
    couponTitle: "Give 100 kr & Get 100 kr",
    manageSubscriptionDescription:
      "Update your subscription to work just like you want.",
    capsules: "Capsules",
    capsulesPackage: "Capsules Package",
    yourCurrentPlanHas: "Your current plan has",
    updatePreferences: "Update Preferences",
    close: "Close",
    everyTwoWeeks: "Every 2 weeks",
    everyFourWeeks: "Every 4 weeks",
    everySixWeeks: "Every 6 weeks",
    everyEightWeeks: "Every 8 weeks",
    everyTwelveWeeks: "Every 12 weeks",
    "two-weeks": "Every two weeks",
    "four-weeks": "Every four weeks",
    "six-weeks": "Every six weeks",
    "eight-weeks": "Every eight weeks",
    "twelve-weeks": "Every twelve weeks",

    "4-weeks": "4 weeks",
    "6-weeks": "6 weeks",
    "8-weeks": "8 weeks",
    capsule: "Capsule",

    currentCapsules: "Your current plan has 120 capsules package.",
    deliveryFrequency: "Delivery Frequency",
    deliverySchedule: "Delivery Schedule",
    nextBillingDate: "Next Billing Date",
    update: "Update",
    discountCode: "Discount Code",
    addItToYourNextOrder: "Add it to your next order",
    paymentMethod: "Payment Method",
    editBillingAddress: "Edit Billing Address",
    changePaymentMethod: "Change Payment Method",
    changePaymentMethodMessage:
      "When you change your payment method, a purchase needs to be made to validate your payment method. It is important that you do not change your e-mail or phone number at checkout when you update your payment method",
    deliveryAddress: "Delivery Address",
    editDeliveryAddress: "Edit Delivery Address",
    edit: "Edit",
    remove: "Remove",
    cancelOrPause: "Skip delivery or cancel subscription",
    cancel: "Cancel",
    cancelDelivery: "Cancel Delivery",
    sorryToLeave:
      "We're sorry to hear you're looking to cancel, but could you please, let us know",
    iWantItNow: "I want it now",
    subscriptionUpdated: "Subscription updated successfully",
    copyCode: "Copy Code",
    product: "Product",
    apply: "Apply",
    applyDiscountCodeHere: "Apply discount code here",
    activity: "Activity",
    skipDelivery: "Skip Delivery",
    areYouSureYouWantToPause:
      "Are you sure you want to pause your subscription?",
    areYouSureYouWantToUnPause:
      "Are you sure you want to unpause your subscription?",
    pause: "Skip delivery",
    weWillCalculateOnNextDelivery:
      " We'll use this date to calculate your next delivery.",
    pauseSubscription: "Skip delivery",
    unpauseSubscription: "Reset skip delivery",
    unpause: "Reset skip delivery",
    customerUpdated: "Customer updated successfully",
    customerUpdateFailed: "Customer update failed",
    discountCodeError: "An error occurred while applying the discount code",
    discountCodeAppliedDescription: "Your discount code has been applied",
    discountCodeApplied: "Discount code applied",
    usedPoints: "Used Points",

    additionalItems: "Additional Items",
    editDiscountCodes: "Edit Discount Codes",
    subscriptionList: "Subscription List",
    january: "January",
    february: "February",
    march: "March",
    april: "April",
    may: "May",
    june: "June",
    july: "July",
    august: "August",
    september: "September",
    october: "October",
    november: "November",
    december: "December",
    pointsTooltip: "Every 10 kr spent is 1 point",
    myDetails: "My Details",
    membershipNumber: "Membership Number",
    name: "Name",
    phone: "Phone",
    email: "Email",
    firstName: "First Name",
    lastName: "Last Name",
    country: "Country",
    city: "City",
    save: "Save",
    change: "Change",
    changeAddress: "Change Address",
    postalCode: "Postal Code",
    changeDeliveryFrequency: "Change Delivery Frequency",
    changeCapsulesQuantity: "Change Capsules Quantity",
    changeCapsulesQuantityDescription:
      "Change the number of capsules you receive with each delivery.",
    changeCapsulesBlends: "Change Capsules Blends",
    weeks: "weeks",
    updateYourNextDeliveryDate: "Update Your Next Delivery Date",
    editYourDiscountCode: "Edit Your Discount Code",
    areYouSureYouWantToCancel: "Are you sure you want to cancel?",
    cancelSubscriptionSorryToHear:
      "We're sorry to hear you're looking to cancel, but could you please let us know",
    selectReason: "Select a reason",
    letUsKnowHowWeCanImprove: "Let us know how we can improve",
    cancelSubscription: "Cancel Subscription",
    cancelSubscriptionDescription:
      "Are you sure you want to cancel your subscription?",
    noIDontWant: "No, I don't want",
    beAlwaysFresh: "Be always fresh with Coffydoor.",
    myCoffydoorPortal: " My Coffydoor Portal",

    allSubscriptions: "All Subscriptions",
    discountCodes: "Discount Codes",
    type: "Type",
    status: "Status",
    delete: "Delete",
    search: "Search",
    noResults: "No results",
    reset: "Reset",
    customer: "Customer",
    subscriptionActivity: "Subscription Activity",
    billNow: "Bill Now",
    subscriptionInformation: "Subscription Information",
    price: "Price",
    frequency: "Frequency",
    nextChargeDate: "Next charge date",
    editSubscriptionPage: "Edit subscription page",
    interval: "Interval",
    removeAddon: "Remove addon",
    changeAddon: "Change addon",
    yourNextDeliveryWillBeAt: "Your next delivery will be at",
    subscriptionFailedToUpdate: "Subscription failed to update",
    updateNextDeliveryDate: "Update next delivery date",
    address: "Address",
    subscriptionUnpaused: "Subscription un-paused",
    subscriptionUnpausedDescription:
      "Your subscription has been un-paused successfully.",

    zipCode: "Zip Code",
    province: "Province",
    company: "Company",
    phoneNumber: "Phone Number",
    editDeliveryDetails: "Edit delivery details",
    saveChanges: "Save changes",
    createDiscountCode: "Create discount code",
    code: "Code",
    used: "Used",
    limit: "Limit",
    limitType: "Limit Type",
    title: "Title",
    value: "Discount Code Value",
    limitedUsage: "Limited usage",
    startedDate: "Start date",
    endDate: "End date",
    doesItHaveEndDate: "Does it have an end date?",

    // ** Cancel Wizard
    hi: "Hi",

    leavingConfirmation: "Are you really leaving already?",
    pointsEarned: "You have earned:",
    membershipPoints: "membership points",
    pointsBenefits: "The points give you:",
    exclusiveAccess: "Exclusive priority access to campaigns and news",
    shopAccess: "Can be used for shopping in our upcoming member shop",
    pointsWarning: "These points will be lost forever..",

    discountOfferMain:
      "That's why we're giving you an 18% discount on your next two deliveries",
    discountOfferCondition:
      "if you choose to continue starting your day with us",

    keepBenefits: "Keep benefits",
    acceptOffer: "Accept offer",
    doesNotFitMyBudget: "Does not fit my budget",
    tooMuchCoffeeAtHome: "Too much coffee at home",
    iNeedSendOuts: "I need send outs faster",
    theTasteWasNotForMe: "The taste was not for me",
    other: "Other",
    specialDiscount: "Special discount for you",
    weReallyWant:
      "We really want you to stay, because everyone deserves a morning with great coffee. That's why we're giving you",
    off: "18% off",
    yourNextTwoDeliveries:
      "your next two deliveries if you continue to start your day with us.",
    youAreAlwaysJust:
      "You are always just a few great friends visiting away from running out of coffee..",
    maybePause: "Maybe a pause would do?",
    cancelPauseSubscription: "Pause subscription",
    wouldYouLikeToPause: "Would you like to pause instead?",
    changeQuantity: "Change quantity",
    promtToUpgrade: "Promt to upgrade their plan to more capsule",
    iNeedMoreSendouts: "I need more capsules",
    sendNowCapslar: "Send now capsules",
    didYouKnow:
      "Did you know that you can brew our Espresso and Ristretto as big cups as well for more intensity?",
    changeSendouts:
      " Did you know that you can change your sendouts to other flavors and just order your favoruites?",
    changeFlavors: "Change flavors",
    updateFlavors: "Update flavors",
    whatWeCanDoBetter: "What can we do better?",
    missUnderstanding:
      "If there has been a missunderstanding we will come back to you with greats news.",
    iGuess: "I guess this is it",
    tellUsWhatYouThink: "Tell us what you think",
    aboutToLose: "You are about to lose your",
    avarageSubscription: "* 22% average subscription discount",
    vipMember: "* VIP-member support",
    isThereAnything: "Is there anything we can do?",
    sendUsEmail: "Send us an email here",

    coffeeRunout: "Out of coffee again?",
    planUpgrade: "Choose a larger plan and save per capsule",

    selectFavorites: "Just choose your favorites",

    freeDelivery: "always free home delivery",
    otherBenefits: "Other benefits",
  },
};
