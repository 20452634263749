import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  CustomerPortalCancelSubscriptionPayload,
  NextDeliveryDatePayload,
  UpdateSubscriptionCapsulesAndQuantityPackagePayload,
  UpdateSubscriptionIntervalPayload
} from '@/core';
import { serviceHost } from '@/services';

const subscriptionService = serviceHost.getSubscriptionService();


export const useUpdateSubscriptionCapsulesAndQuantityPackage = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["subscription", "mutation", "update"],
    mutationFn: (
      payload: UpdateSubscriptionCapsulesAndQuantityPackagePayload,
    ) => {
      return subscriptionService.updateSubscriptionCapsulesAndQuantityPackage(
        payload,
      );
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["subscription"],
      });
    },
  });
};

export const useSkipSubscription = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["subscription", "mutation", "pause"],
    mutationFn: () => {
      return subscriptionService.skipSubscription();
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["subscription"],
      });
    },
  });
};

export const useCancelSubscription = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["subscription", "mutation", "cancel"],
    mutationFn: (payload: CustomerPortalCancelSubscriptionPayload) => {
      return subscriptionService.cancelSubscription(payload);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["subscription"],
      });
    },
  });
};
export const useReActivateSubscription = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["subscription", "mutation", "reactivate"],
    mutationFn: () => {
      return subscriptionService.reactivateSubscription();
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["subscription"],
      });
    },
  });
};

export const useUnSkipSubscription = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["subscription", "mutation", "unpause"],
    mutationFn: () => {
      return subscriptionService.unSkipSubscription();
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["subscription"],
      });
    },
  });
};

export const useUpdateNextDeliveryDate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["subscription", "mutation", "update"],
    mutationFn: (payload: NextDeliveryDatePayload) => {
      return subscriptionService.updateNextDeliveryDate(payload);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["subscription"],
      });
    },
  });
};

export const useUpdateSubscriptionInterval = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["subscription", "mutation", "update"],
    mutationFn: (payload: UpdateSubscriptionIntervalPayload) => {
      return subscriptionService.updateSubscriptionInterval(payload);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["subscription"],
      });
    },
  });
};

export const useBillNow = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["subscription", "mutation", "billNow"],
    mutationFn: () => {
      return subscriptionService.billNow();
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["subscription"],
      });
    }
  });
}

export const useUpdateSubscriptionAndBillCustomer = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["subscription", "mutation", "update"],
    mutationFn: async (payload: UpdateSubscriptionCapsulesAndQuantityPackagePayload) => {
      await subscriptionService.updateSubscriptionCapsulesAndQuantityPackage(payload);

      await subscriptionService.billNow();
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["subscription"],
      });
    },
  });
};
