import { useTranslation } from "react-i18next";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import { FC, useCallback, useEffect, useMemo, useState } from "react";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import coffydoorVideo from "@/assets/video/coffydoor.MP4";

import firstGif from "@/assets/gif/first.gif";
import thirdGif from "@/assets/gif/third.gif";

import { Button } from "@/components/ui/button.tsx";

import {
  useApplyDiscountCode,
  useAuthUser,
  useCancelSubscription,
  useGetCustomer,
  useGetCustomerPoints,
  useGetSubscription,
  useUpdateSubscriptionCapsulesAndQuantityPackage,
  useUpdateSubscriptionInterval,
} from "@/hooks";

import { Textarea } from "@/components/ui/textarea.tsx";
import { Separator } from "@/components/ui/separator.tsx";
import { EmptyState } from "@/components/shared/EmptyState.tsx";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog.tsx";

import { cn } from "@/lib/utils.ts";

import {
  CapsulesConfig,
  Customer,
  CustomerPoints,
  ProductBundleCapsulesQuantity,
  Subscription,
  SubscriptionInterval,
} from "@/core";

import {
  CancelReason,
  CancelWizardScreens,
  useCancelWizardStore,
} from "@/store/cancel-wizard-store.ts";
import ReactPlayer from "react-player";
import { LoadingState } from "@/components/shared/LoadingState.tsx";
import { DrawerTitle } from "@/components/ui/drawer.tsx";
import { Icons } from "@/components/ui/icons.tsx";
import { toast } from "@/components/ui/use-toast";

export const CancelWizard: FC = () => {
  const { t } = useTranslation("translation");
  const authUser = useAuthUser();

  const currentScreen = useCancelWizardStore((state) => state.currentScreen);
  const backToMainScreen = useCancelWizardStore(
    (state) => state.backToMainScreen,
  );

  const isWizardOpen = useCancelWizardStore((state) => state.isWizardOpen);
  const setIsWizardOpen = useCancelWizardStore(
    (state) => state.setIsWizardOpen,
  );
  const closeWizard = useCancelWizardStore((state) => state.closeWizard);

  const subscription = useGetSubscription({
    id: authUser?.uid || "",
  });

  const customer = useGetCustomer({
    id: authUser?.uid || "",
  });

  const customerPoints = useGetCustomerPoints({
    id: authUser?.uid || "",
  });

  // Preload images
  useEffect(() => {
    const imageUrls = [firstGif, thirdGif];

    imageUrls.forEach((url) => {
      const img = new Image();
      img.src = url;
    });
  }, []);

  if (
    subscription.isLoading ||
    customer.isLoading ||
    customerPoints.isLoading
  ) {
    return <LoadingState />;
  }

  if (!subscription.data || !customer.data || !customerPoints.data) {
    return <EmptyState />;
  }

  return (
    <Dialog
      open={isWizardOpen}
      onOpenChange={(open) => {
        backToMainScreen();
        setIsWizardOpen(open);
      }}
    >
      <DialogTrigger asChild>
        <Button variant="invisible" className={"justify-end"}>
          {t("cancel")}
        </Button>
      </DialogTrigger>

      <DialogContent
        className={
          "w-[95vw] max-w-3xl max-h-[80vh] rounded-lg overflow-y-auto overflow-x-hidden p-4 md:p-6"
        }
        onInteractOutside={closeWizard}
      >
        {currentScreen === CancelWizardScreens.MAIN && (
          <MainScreenCancelWizard
            customer={customer.data}
            customerPoints={customerPoints.data}
          />
        )}

        {currentScreen === CancelWizardScreens.CANCEL_OPTIONS && (
          <OptionsCancelWizard />
        )}

        {currentScreen === CancelWizardScreens.DOES_NOT_FIT_MY_BUDGET && (
          <DoesNotFitScreen />
        )}

        {currentScreen === CancelWizardScreens.TOO_MUCH_COFFEE_AT_HOME && (
          <TooMuchCoffeeAtHomeScreen customer={customer.data} />
        )}

        {currentScreen === CancelWizardScreens.I_NEED_SEND_OUTS && (
          <INeedSendOutsScreen subscription={subscription.data} />
        )}

        {currentScreen === CancelWizardScreens.THE_TASTE_WAS_NOT_FOR_ME && (
          <TheTasteWasNotForMeScreen subscription={subscription.data} />
        )}

        {currentScreen === CancelWizardScreens.OTHER && <OtherScreen />}

        {currentScreen === CancelWizardScreens.FINAL_CANCEL && (
          <CancelWizardFinalCancelScreen
            customer={customer.data}
            customerPoints={customerPoints.data}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

interface MainScreenCancelWizardProps {
  customer: Customer;
  customerPoints: CustomerPoints;
}

export const MainScreenCancelWizard = (props: MainScreenCancelWizardProps) => {
  const { customer, customerPoints } = props;
  const { t } = useTranslation("translation");

  const setCurrentScreen = useCancelWizardStore(
    (state) => state.setCurrentScreen,
  );

  return (
    <>
      <DialogHeader>
        <DialogTitle className={"text-center font-hkgwb uppercase"}>
          {t("cancelSubscription")}
        </DialogTitle>
      </DialogHeader>
      <div className={"text-center"}>
        <div className={"max-w-[350px] sm:max-w-sm mx-auto mb-4 "}>
          <p className={""}>
            {t("hi")} <strong>{customer.firstName}</strong>
          </p>

          <p className={"truncate"}>{t("leavingConfirmation")}</p>
          <br />
          <p className={"truncate"}>{t("pointsEarned")}</p>
          <p className={"flex items-center justify-center font-bold"}>
            <Icons.star size={16} />
            {customerPoints.points} {t("membershipPoints")}
          </p>
          <br />
          {/*<p className={"truncate"}>{t("pointsBenefits")}</p>*/}
          {/*<p className={"truncate"}>{t("exclusiveAccess")}</p>*/}
          {/*<p className={"truncate"}>{t("shopAccess")}</p>*/}
          {/*<br />*/}
          <p className={"truncate"}>{t("pointsWarning")}</p>
        </div>
        <div className="flex justify-center mb-4">
          <div className="relative w-[180px] aspect-[9/16] bg-black">
            <ReactPlayer
              url={coffydoorVideo}
              playing={true}
              controls
              width="100%"
              height="100%"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
              }}
              config={{
                file: {
                  attributes: {
                    style: {
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    },
                  },
                },
              }}
            />
          </div>
        </div>
        <div className={"w-full"}>
          <div className={""}>
            <div className={cn("flex justify-center")}>
              <DialogPrimitive.DialogClose>
                <Button
                  variant={"black"}
                  className={"bg-black text-white hover:bg-black/90"}
                >
                  {t("keepBenefits")}
                </Button>
              </DialogPrimitive.DialogClose>
            </div>

            <div
              className={
                "flex justify-center font-normal items-center text-[8px]"
              }
            >
              <Button
                variant={"invisible"}
                onClick={() => {
                  setCurrentScreen(CancelWizardScreens.CANCEL_OPTIONS);
                }}
                className={cn("text-[8px]")}
              >
                {t("cancel")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const OptionsCancelWizard = () => {
  const { t } = useTranslation("translation");

  const setCurrentScreen = useCancelWizardStore(
    (state) => state.setCurrentScreen,
  );
  const setCancelReason = useCancelWizardStore(
    (state) => state.setCancelReason,
  );

  const buttons = useMemo(() => {
    return [
      {
        title: t("doesNotFitMyBudget"),
        onClick: () => {
          setCurrentScreen(CancelWizardScreens.DOES_NOT_FIT_MY_BUDGET);
          setCancelReason(CancelReason.DOES_NOT_FIT);
        },
      },
      {
        title: t("tooMuchCoffeeAtHome"),
        onClick: () => {
          setCurrentScreen(CancelWizardScreens.TOO_MUCH_COFFEE_AT_HOME);
          setCancelReason(CancelReason.TOO_MUCH_COFFEE_AT_HOME);
        },
      },
      {
        title: t("iNeedSendOuts"),
        onClick: () => {
          setCurrentScreen(CancelWizardScreens.I_NEED_SEND_OUTS);
          setCancelReason(CancelReason.I_NEED_SEND_OUTS);
        },
      },
      {
        title: t("theTasteWasNotForMe"),
        onClick: () => {
          setCurrentScreen(CancelWizardScreens.THE_TASTE_WAS_NOT_FOR_ME);
          setCancelReason(CancelReason.THE_TASTE_WAS_NOT_FOR_ME);
        },
      },
      {
        title: t("other"),
        onClick: () => {
          setCurrentScreen(CancelWizardScreens.OTHER);
          setCancelReason(CancelReason.OTHER);
        },
      },
    ];
  }, [setCancelReason, setCurrentScreen, t]);

  return (
    <div>
      <div className={"grid "}>
        {buttons.map((button, index) => (
          <Button
            key={index}
            onClick={button.onClick}
            className={"line-clamp-1 mt-4 text-xs"}
          >
            {button.title}
          </Button>
        ))}
      </div>

      <div className={"w-full"}>
        <div className={""}>
          <div className={cn("flex justify-center")}>
            <DialogPrimitive.DialogClose>
              <Button
                variant={"black"}
                className={"bg-black mt-4 text-white hover:bg-black/90"}
                onClick={() => {}}
              >
                {t("keepBenefits")}
              </Button>
            </DialogPrimitive.DialogClose>
          </div>
        </div>
      </div>
    </div>
  );
};

export const DoesNotFitScreen = () => {
  const { t } = useTranslation("translation");

  const setCurrentScreen = useCancelWizardStore(
    (state) => state.setCurrentScreen,
  );
  const closeWizard = useCancelWizardStore((state) => state.closeWizard);

  const applyDiscountCode = useApplyDiscountCode();

  const onDiscountApply = useCallback(() => {
    applyDiscountCode.mutate({
      code: "kaffe18",
    });
  }, [applyDiscountCode]);

  useEffect(() => {
    if (applyDiscountCode.isSuccess) {
      toast({
        title: t("subscriptionUpdated"),
      });

      closeWizard();
    }
  }, [applyDiscountCode.isSuccess, t, closeWizard]);

  return (
    <>
      <DialogHeader>
        <DialogTitle className={"text-center font-hkgwb uppercase"}>
          {t("specialDiscount")}
        </DialogTitle>
      </DialogHeader>
      <div className={"text-center"}>
        <p className={"w-4/5 mx-auto mt-4"}>
          {t("weReallyWant")}
          <br />
          <br />
          <span className={"text-brand-black font-black"}>
            {t("discountOfferMain")}
          </span>{" "}
          <br />
          <br />
          {t("discountOfferCondition")}.
        </p>

        <div className={"flex justify-center"}>
          <img
            src={firstGif}
            alt="coffe box"
            className="w-4/5 h-1/2 mx-auto mt-4 object-cover"
          />
        </div>

        <Button
          disabled={applyDiscountCode.isPending}
          onClick={onDiscountApply}
          className={"mt-4"}
        >
          {applyDiscountCode.isPending && <Icons.loading />} {t("acceptOffer")}
        </Button>

        <div className={"w-full"}>
          <div className={""}>
            <div
              className={
                "flex justify-center font-normal items-center text-[8px]"
              }
            >
              <Button
                disabled={applyDiscountCode.isPending}
                variant={"invisible"}
                onClick={() => {
                  setCurrentScreen(CancelWizardScreens.FINAL_CANCEL);
                }}
                className={cn("text-[8px]")}
              >
                {t("cancel")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

interface TooMuchCoffeeAtHomeScreenProps {
  customer: Customer;
}

export const TooMuchCoffeeAtHomeScreen = (
  props: TooMuchCoffeeAtHomeScreenProps,
) => {
  const { customer } = props;
  const { t } = useTranslation("translation");

  const closeWizard = useCancelWizardStore((state) => state.closeWizard);
  const setCurrentScreen = useCancelWizardStore(
    (state) => state.setCurrentScreen,
  );

  const updateSubscriptionInterval = useUpdateSubscriptionInterval();

  const updateInterval = useCallback(
    (interval: SubscriptionInterval) => {
      updateSubscriptionInterval.mutate({
        interval,
      });
    },
    [updateSubscriptionInterval],
  );

  useEffect(() => {
    if (updateSubscriptionInterval.isSuccess) {
      toast({
        title: t("subscriptionUpdated"),
      });

      closeWizard();
    }
  }, [updateSubscriptionInterval.isSuccess, t, closeWizard]);

  return (
    <div className={"text-center w-full sm:w-4/6 sm:mx-auto"}>
      <h1 className={"font-hkgwb text-lg mb-4 uppercase rounded-md"}>
        {t("cancelPauseSubscription")} {customer.firstName}?
      </h1>
      <p className={"mb-4"}>{t("youAreAlwaysJust")}</p>
      <p className={""}>{t("maybePause")}</p>

      <div className={"grid grid-cols-1 sm:grid-cols-3 gap-4 my-4"}>
        <Button
          disabled={updateSubscriptionInterval.isPending}
          onClick={() => updateInterval(SubscriptionInterval.FOUR_WEEKS)}
        >
          {updateSubscriptionInterval.isPending && <Icons.loading />}{" "}
          {t("4-weeks")}
        </Button>
        <Button
          disabled={updateSubscriptionInterval.isPending}
          onClick={() => updateInterval(SubscriptionInterval.SIX_WEEKS)}
        >
          {updateSubscriptionInterval.isPending && <Icons.loading />}{" "}
          {t("6-weeks")}
        </Button>
        <Button
          disabled={updateSubscriptionInterval.isPending}
          onClick={() => updateInterval(SubscriptionInterval.EIGHT_WEEKS)}
        >
          {updateSubscriptionInterval.isPending && <Icons.loading />}{" "}
          {t("8-weeks")}
        </Button>
      </div>

      <div className={"w-full"}>
        <div className={""}>
          <div
            className={
              "flex justify-center font-normal items-center text-[8px]"
            }
          >
            <Button
              variant={"invisible"}
              disabled={updateSubscriptionInterval.isPending}
              onClick={() => {
                setCurrentScreen(CancelWizardScreens.FINAL_CANCEL);
              }}
              className={cn("text-[8px]")}
            >
              {t("cancel")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

interface INeedSendOutsScreenProps {
  subscription: Subscription;
}

export const INeedSendOutsScreen = (props: INeedSendOutsScreenProps) => {
  const { subscription } = props;
  const { t } = useTranslation("translation");

  const closeWizard = useCancelWizardStore((state) => state.closeWizard);
  const setCurrentScreen = useCancelWizardStore(
    (state) => state.setCurrentScreen,
  );

  const items = subscription.subscriptionProduct.capsulesConfig;
  const [capsules, setCapsules] = useState<CapsulesConfig>(items);

  const [capsulePackage, setCapsulePackage] = useState(
    subscription.subscriptionProduct.capsulesQuantity,
  );

  const currentQuantity =
    capsules.lungo + capsules.espresso + capsules.ristretto + capsules.decaf;

  const totalQuantity = capsulePackage;

  const updateSubscriptionCapsulesAndQuantity =
    useUpdateSubscriptionCapsulesAndQuantityPackage();

  const updateSubscription = useCallback(() => {
    updateSubscriptionCapsulesAndQuantity.mutate({
      capsulesQuantity: capsulePackage,
      capsulesConfig: capsules,
    });
  }, [capsulePackage, capsules, updateSubscriptionCapsulesAndQuantity]);

  useEffect(() => {
    if (updateSubscriptionCapsulesAndQuantity.isSuccess) {
      toast({
        title: t("subscriptionUpdated"),
      });

      closeWizard();
    }
  }, [updateSubscriptionCapsulesAndQuantity.isSuccess, t, closeWizard]);

  const quantityChange = [
    {
      label: `60 ${t("capsules")}`,
      onClick: () => {
        setCapsulePackage(ProductBundleCapsulesQuantity.SIXTY);

        setCapsules({
          lungo: 20,
          espresso: 20,
          ristretto: 20,
          decaf: 0,
        });
      },
    },
    {
      label: `120 ${t("capsules")}`,
      onClick: () => {
        setCapsulePackage(ProductBundleCapsulesQuantity.ONE_HUNDRED_TWENTY);

        setCapsules({
          lungo: 40,
          espresso: 40,
          ristretto: 40,
          decaf: 0,
        });
      },
    },
    {
      label: `250 ${t("capsules")}`,
      onClick: () => {
        setCapsulePackage(ProductBundleCapsulesQuantity.TWO_HUNDRED_FIFTY);

        setCapsules({
          lungo: 80,
          espresso: 80,
          ristretto: 80,
          decaf: 10,
        });
      },
    },
    {
      label: `500 ${t("capsules")}`,
      onClick: () => {
        setCapsulePackage(ProductBundleCapsulesQuantity.FIVE_HUNDRED);

        setCapsules({
          lungo: 160,
          espresso: 160,
          ristretto: 160,
          decaf: 20,
        });
      },
    },
  ];

  const orderQuantity = [
    {
      name: "Espresso",
      value: capsules.espresso,
      onClick: (value: number) => setCapsules({ ...capsules, espresso: value }),
    },
    {
      name: "Ristretto",
      value: capsules.ristretto,
      onClick: (value: number) =>
        setCapsules({ ...capsules, ristretto: value }),
    },
    {
      name: "Lungo",
      value: capsules.lungo,
      onClick: (value: number) => setCapsules({ ...capsules, lungo: value }),
    },
    {
      name: "Decaf",
      value: capsules.decaf,
      onClick: (value: number) => setCapsules({ ...capsules, decaf: value }),
    },
  ];

  const isAbleToIncreaseCapsules = useMemo(() => {
    if (!totalQuantity || !currentQuantity) {
      return;
    }

    return currentQuantity >= totalQuantity;
  }, [totalQuantity, currentQuantity]);

  const capsulesColor = useCallback((name: string) => {
    if (name === "Espresso") {
      return "bg-brand-celezte";
    }

    if (name === "Ristretto") {
      return "bg-brand-black";
    }

    if (name === "Lungo") {
      return "bg-white border";
    }

    if (name === "Decaf") {
      return "bg-brand-powder-blue";
    }

    return "";
  }, []);

  return (
    <div className={"text-center"}>
      <div>
        <h1 className={"font-hkgwb text-lg mb-2 uppercase rounded-md"}>
          {t("coffeeRunout")}
        </h1>
        <p className={"mb-4"}>{t("planUpgrade")}</p>

        <div className={"flex justify-center"}>
          <div className={"overflow-auto"}>
            {quantityChange && (
              <div className={"grid grid-cols-2 gap-2"}>
                {quantityChange.map((item, index) => {
                  const variant = item.label.includes(`${capsulePackage}`)
                    ? "celezte-light"
                    : "outline";

                  return (
                    <Button
                      key={index}
                      variant={variant}
                      size={"sm"}
                      type={"button"}
                      onClick={item.onClick}
                      className="break-all text-wrap"
                    >
                      {item.label}
                    </Button>
                  );
                })}
              </div>
            )}

            <div className={"text-center my-4 font-hkgwb uppercase"}>
              {t("changeCapsulesBlends")}
            </div>

            {orderQuantity && (
              <div className={"grid grid-cols-2 lg:grid-cols-4 gap-2 "}>
                {orderQuantity.map((item, index) => (
                  <div
                    key={index}
                    className={
                      "flex flex-col w-full py-2 text-center bg-secondary rounded-lg"
                    }
                  >
                    <div className={"flex justify-center"}>
                      <div
                        className={`w-4 h-4 ${capsulesColor(item.name)} rounded-full mt-2`}
                      >
                        {/*<Icons.capsule />*/}
                      </div>
                    </div>
                    <div className={"mt-4"}>
                      <DrawerTitle className={"font-extralight"}>
                        {item.name}
                      </DrawerTitle>
                    </div>

                    <div
                      className={"flex items-center justify-center gap-2 my-2"}
                    >
                      <Button
                        variant="outline"
                        size="icon"
                        onClick={() => {
                          const { onClick } = item;

                          if (item.value === 0) {
                            return;
                          }

                          onClick(item.value - 10);
                        }}
                      >
                        <Icons.minus />
                      </Button>
                      <DrawerTitle
                        className={"font-semibold w-8 text-foreground"}
                      >
                        {item.value}
                      </DrawerTitle>
                      <Button
                        variant="outline"
                        size="icon"
                        disabled={isAbleToIncreaseCapsules}
                        onClick={() => {
                          const { onClick } = item;

                          onClick(item.value + 10);
                        }}
                      >
                        <Icons.plus />
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      {/*Jag behöver mer kaffe NU*/}
      <div className={"w-11/12 sm:w-1/2 mx-auto"}>
        <Button
          disabled={updateSubscriptionCapsulesAndQuantity.isPending}
          onClick={updateSubscription}
          className={"mt-4 bg-black text-white hover:bg-black/90"}
        >
          {updateSubscriptionCapsulesAndQuantity.isPending && <Icons.loading />}{" "}
          {t("saveChanges")}
        </Button>
      </div>

      <div className={"w-full"}>
        <div className={""}>
          <div
            className={
              "flex justify-center font-normal items-center text-[8px]"
            }
          >
            <Button
              variant={"invisible"}
              disabled={updateSubscriptionCapsulesAndQuantity.isPending}
              onClick={() => {
                setCurrentScreen(CancelWizardScreens.FINAL_CANCEL);
              }}
              className={cn("text-[8px]")}
            >
              {t("cancel")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

interface TheTasteWasNotForMeScreenProps {
  subscription: Subscription;
}

export const TheTasteWasNotForMeScreen = (
  props: TheTasteWasNotForMeScreenProps,
) => {
  const { subscription } = props;
  const { t } = useTranslation("translation");

  const closeWizard = useCancelWizardStore((state) => state.closeWizard);
  const setCurrentScreen = useCancelWizardStore(
    (state) => state.setCurrentScreen,
  );

  const items = subscription.subscriptionProduct.capsulesConfig;
  const [capsules, setCapsules] = useState<CapsulesConfig>(items);

  const [capsulePackage, setCapsulePackage] = useState(
    subscription.subscriptionProduct.capsulesQuantity,
  );

  const currentQuantity =
    capsules.lungo + capsules.espresso + capsules.ristretto + capsules.decaf;

  const totalQuantity = capsulePackage;

  const updateSubscriptionCapsulesAndQuantity =
    useUpdateSubscriptionCapsulesAndQuantityPackage();

  const updateSubscription = useCallback(() => {
    updateSubscriptionCapsulesAndQuantity.mutate({
      capsulesQuantity: capsulePackage,
      capsulesConfig: capsules,
    });
  }, [capsulePackage, capsules, updateSubscriptionCapsulesAndQuantity]);

  useEffect(() => {
    if (updateSubscriptionCapsulesAndQuantity.isSuccess) {
      toast({
        title: t("subscriptionUpdated"),
      });

      closeWizard();
    }
  }, [updateSubscriptionCapsulesAndQuantity.isSuccess, t, closeWizard]);

  const quantityChange = [
    {
      label: `60 ${t("capsules")}`,
      onClick: () => {
        setCapsulePackage(ProductBundleCapsulesQuantity.SIXTY);

        setCapsules({
          lungo: 20,
          espresso: 20,
          ristretto: 20,
          decaf: 0,
        });
      },
    },
    {
      label: `120 ${t("capsules")}`,
      onClick: () => {
        setCapsulePackage(ProductBundleCapsulesQuantity.ONE_HUNDRED_TWENTY);

        setCapsules({
          lungo: 40,
          espresso: 40,
          ristretto: 40,
          decaf: 0,
        });
      },
    },
    {
      label: `250 ${t("capsules")}`,
      onClick: () => {
        setCapsulePackage(ProductBundleCapsulesQuantity.TWO_HUNDRED_FIFTY);

        setCapsules({
          lungo: 80,
          espresso: 80,
          ristretto: 80,
          decaf: 10,
        });
      },
    },
    {
      label: `500 ${t("capsules")}`,
      onClick: () => {
        setCapsulePackage(ProductBundleCapsulesQuantity.FIVE_HUNDRED);

        setCapsules({
          lungo: 160,
          espresso: 160,
          ristretto: 160,
          decaf: 20,
        });
      },
    },
  ];

  const orderQuantity = [
    {
      name: "Espresso",
      value: capsules.espresso,
      onClick: (value: number) => setCapsules({ ...capsules, espresso: value }),
    },
    {
      name: "Ristretto",
      value: capsules.ristretto,
      onClick: (value: number) =>
        setCapsules({ ...capsules, ristretto: value }),
    },
    {
      name: "Lungo",
      value: capsules.lungo,
      onClick: (value: number) => setCapsules({ ...capsules, lungo: value }),
    },
    {
      name: "Decaf",
      value: capsules.decaf,
      onClick: (value: number) => setCapsules({ ...capsules, decaf: value }),
    },
  ];

  const isAbleToIncreaseCapsules = useMemo(() => {
    if (!totalQuantity || !currentQuantity) {
      return;
    }

    return currentQuantity >= totalQuantity;
  }, [totalQuantity, currentQuantity]);

  const capsulesColor = useCallback((name: string) => {
    if (name === "Espresso") {
      return "bg-brand-celezte";
    }

    if (name === "Ristretto") {
      return "bg-brand-black";
    }

    if (name === "Lungo") {
      return "bg-white border";
    }

    if (name === "Decaf") {
      return "bg-brand-powder-blue";
    }

    return "";
  }, []);

  return (
    <div className={"text-center"}>
      <div>
        <h1 className={"font-hkgwb text-lg mb-2 uppercase rounded-md"}>
          {t("selectFavorites")}
        </h1>

        <div className={"flex justify-center"}>
          <div className={"overflow-auto"}>
            {quantityChange && (
              <div className={"grid grid-cols-2 gap-2"}>
                {quantityChange.map((item, index) => {
                  const variant = item.label.includes(`${capsulePackage}`)
                    ? "celezte-light"
                    : "outline";

                  return (
                    <Button
                      key={index}
                      variant={variant}
                      size={"sm"}
                      type={"button"}
                      onClick={item.onClick}
                      className="break-all text-wrap"
                    >
                      {item.label}
                    </Button>
                  );
                })}
              </div>
            )}

            <div className={"text-center my-4 font-hkgwb uppercase"}>
              {t("changeCapsulesBlends")}
            </div>

            {orderQuantity && (
              <div className={"grid grid-cols-2 lg:grid-cols-4 gap-2 "}>
                {orderQuantity.map((item, index) => (
                  <div
                    key={index}
                    className={
                      "flex flex-col w-full py-2 text-center bg-secondary rounded-lg"
                    }
                  >
                    <div className={"flex justify-center"}>
                      <div
                        className={`w-4 h-4 ${capsulesColor(item.name)} rounded-full mt-2`}
                      >
                        {/*<Icons.capsule />*/}
                      </div>
                    </div>
                    <div className={"mt-4"}>
                      <DrawerTitle className={"font-extralight"}>
                        {item.name}
                      </DrawerTitle>
                    </div>

                    <div
                      className={"flex items-center justify-center gap-2 my-2"}
                    >
                      <Button
                        variant="outline"
                        size="icon"
                        onClick={() => {
                          const { onClick } = item;

                          if (item.value === 0) {
                            return;
                          }

                          onClick(item.value - 10);
                        }}
                      >
                        <Icons.minus />
                      </Button>
                      <DrawerTitle
                        className={"font-semibold w-8 text-foreground"}
                      >
                        {item.value}
                      </DrawerTitle>
                      <Button
                        variant="outline"
                        size="icon"
                        disabled={isAbleToIncreaseCapsules}
                        onClick={() => {
                          const { onClick } = item;

                          onClick(item.value + 10);
                        }}
                      >
                        <Icons.plus />
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      {/*Jag behöver mer kaffe NU*/}
      <div className={"w-11/12 sm:w-1/2 mx-auto"}>
        <Button
          disabled={updateSubscriptionCapsulesAndQuantity.isPending}
          onClick={updateSubscription}
          className={"mt-4 bg-black text-white hover:bg-black/90"}
        >
          {updateSubscriptionCapsulesAndQuantity.isPending && <Icons.loading />}{" "}
          {t("saveChanges")}
        </Button>
      </div>

      <div className={"w-full"}>
        <div className={""}>
          <div
            className={
              "flex justify-center font-normal items-center text-[8px]"
            }
          >
            <Button
              variant={"invisible"}
              disabled={updateSubscriptionCapsulesAndQuantity.isPending}
              onClick={() => {
                setCurrentScreen(CancelWizardScreens.FINAL_CANCEL);
              }}
              className={cn("text-[8px]")}
            >
              {t("cancel")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const OtherScreen = () => {
  const { t } = useTranslation("translation");

  const cancelReason = useCancelWizardStore((state) => state.cancelReason);
  const setCancelReason = useCancelWizardStore(
    (state) => state.setCancelReason,
  );

  const setCurrentScreen = useCancelWizardStore(
    (state) => state.setCurrentScreen,
  );

  return (
    <div className={"text-center"}>
      <p className={"font-hkgwb uppercase"}>{t("whatWeCanDoBetter")}</p>

      <Textarea
        onChange={(e) => setCancelReason(e.target.value)}
        placeholder={t("tellUsWhatYouThink")}
        className={"mt-4"}
      />

      <p className={"mt-4"}>{t("missUnderstanding")}</p>

      <div className={"w-full mt-4"}>
        <div className={""}>
          <div className={cn("flex justify-center")}>
            <DialogPrimitive.DialogClose>
              <Button
                variant={"black"}
                className={"bg-black text-white hover:bg-black/90"}
              >
                {t("keepBenefits")}
              </Button>
            </DialogPrimitive.DialogClose>
          </div>

          <div
            className={
              "flex justify-center font-normal items-center text-[8px]"
            }
          >
            <Button
              disabled={!cancelReason || cancelReason.length < 10}
              variant={"invisible"}
              onClick={() => {
                setCurrentScreen(CancelWizardScreens.FINAL_CANCEL);
              }}
              className={cn("text-[8px]")}
            >
              {t("cancel")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

interface CancelWizardFinalCancelScreenProps {
  customer: Customer;
  customerPoints: CustomerPoints;
}

export const CancelWizardFinalCancelScreen = (
  props: CancelWizardFinalCancelScreenProps,
) => {
  const { customer, customerPoints } = props;

  const { t } = useTranslation("translation");

  const closeWizard = useCancelWizardStore((state) => state.closeWizard);
  const cancelReason = useCancelWizardStore((state) => state.cancelReason);

  const cancelSubscriptionMutation = useCancelSubscription();

  const cancelSubscription = useCallback(() => {
    cancelSubscriptionMutation.mutate({
      reason: cancelReason,
    });
  }, [cancelReason, cancelSubscriptionMutation]);

  useEffect(() => {
    if (cancelSubscriptionMutation.isSuccess) {
      toast({
        title: t("subscriptionUpdated"),
      });

      closeWizard();
    }
  }, [cancelSubscriptionMutation.isSuccess, t, closeWizard]);

  return (
    <div>
      <div className={"text-center"}>
        <p className={"font-hkgwb uppercase"}>
          {t("iGuess")} {customer.firstName}
        </p>

        <Separator className={"my-2"} />

        <p className={"text-center mt-4 mb-2"}>{t("aboutToLose")}</p>

        <div className={"pl-4 mb-4"}>
          <p className={"flex items-center justify-center font-bold mb-2"}>
            <Icons.star size={16} />
            {customerPoints.points} {t("membershipPoints")}
          </p>
          <div>
            <strong>- {t("avarageSubscription")}</strong>
          </div>
          <div>
            <strong>- {t("vipMember")}</strong>
          </div>
          <div>
            <strong>- {t("freeDelivery")}</strong>
          </div>
          <div>
            <strong>- {t("otherBenefits")}</strong>
          </div>
        </div>
      </div>

      <div className={"flex justify-center"}>
        <img
          src={thirdGif}
          alt="coffe box"
          className="w-4/5 h-1/2 mx-auto mt-4 object-cover"
        />
      </div>

      <p className={"text-center mt-4"}>{t("isThereAnything")}</p>

      <div className={"flex justify-center"}>
        <Button
          variant={"black"}
          onClick={() => {
            window.location.href = "mailto:hej@coffydoor.com";
            closeWizard();
          }}
          className={"mt-4 "}
        >
          {t("sendUsEmail")}
        </Button>
      </div>

      <div className={"w-full"}>
        <div className={""}>
          <div
            className={
              "flex justify-center font-normal items-center text-[8px]"
            }
          >
            <Button
              variant={"invisible"}
              disabled={cancelSubscriptionMutation.isPending}
              onClick={cancelSubscription}
              className={cn("text-[8px]")}
            >
              {t("cancel")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
