// import "./instrument";
import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { ThemeProvider } from "@/components/theme-provider.tsx";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import "./i18next.ts";
import "./global.css";
import { router } from "@/routes";

const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider defaultTheme="light" storageKey="vite-ui-theme">
        <RouterProvider router={router} />
        {/*<ReactQueryDevtools buttonPosition={'top-right'} initialIsOpen={false} />*/}
      </ThemeProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);
