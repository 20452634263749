import { create } from "zustand";
import { persist } from "zustand/middleware";

export enum CancelReason {
  DOES_NOT_FIT = "Does not fit my budget",
  TOO_MUCH_COFFEE_AT_HOME = "Too much coffee at home",
  I_NEED_SEND_OUTS = "I need send outs faster",
  THE_TASTE_WAS_NOT_FOR_ME = "The taste was not for me",
  OTHER = "Other",
}

export enum CancelWizardScreens {
  MAIN = "main",
  CANCEL_OPTIONS = "cancel-options",
  DOES_NOT_FIT_MY_BUDGET = "does-not-fit-my-budget",
  TOO_MUCH_COFFEE_AT_HOME = "too-much-coffee-at-home",
  I_NEED_SEND_OUTS = "i-need-send-outs",
  THE_TASTE_WAS_NOT_FOR_ME = "the-taste-was-not-for-me",
  OTHER = "other",
  FINAL_CANCEL = "final-cancel",
}

export interface CancelWizardStore {
  isWizardOpen: boolean;
  setIsWizardOpen: (isOpen: boolean) => void;
  closeWizard: () => void;

  cancelReason: string;
  setCancelReason: (newReason: string) => void;

  currentScreen: CancelWizardScreens;
  setCurrentScreen: (newScreen: CancelWizardScreens) => void;

  backToMainScreen: () => void;
  showCancelScreen: () => void;
}

export const useCancelWizardStore = create<CancelWizardStore>()(
  persist(
    (set) => ({
      isWizardOpen: false,
      setIsWizardOpen: (isOpen) =>
        set((state) => ({
          ...state,
          isWizardOpen: isOpen,
        })),
      closeWizard: () =>
        set((state) => ({
          ...state,
          isWizardOpen: false,
        })),

      cancelReason: "",
      setCancelReason: (newReason) =>
        set((state) => ({
          ...state,
          cancelReason: newReason,
        })),

      currentScreen: CancelWizardScreens.MAIN,
      setCurrentScreen: (newScreen) =>
        set((state) => ({
          ...state,
          currentScreen: newScreen,
        })),

      backToMainScreen: () =>
        set((state) => ({
          ...state,
          currentScreen: CancelWizardScreens.MAIN,
        })),

      showCancelScreen: () => {
        set((state) => ({
          ...state,
          currentScreen: CancelWizardScreens.FINAL_CANCEL,
        }));
      },
    }),
    {
      name: "coffydoor-customer-portal-cancel-wizard-store-v2",
    },
  ),
);
